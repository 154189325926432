.travel-stats {
    width: 100%;
    margin-top: 50px;

    .stats-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
            font-size: 32pt;
        }

        h1 {
            font-size: 32pt;
            font-weight: 800;
            margin: auto;
            text-align: center;
        }
    }
}