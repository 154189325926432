.projects {
    h1 {
        font-size: 32pt;
    }

    .project-grid-row {
        display: grid;
        align-items: center;
        column-gap: 25px;
        margin-bottom: 25px;
        width: calc(100% - 25px);
        transition: all 0.5s ease;

        .project-tile {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            text-align: center;
            padding: 0 25px;
            cursor: pointer;

            .branding-wrapper {
                body.hasHover &:hover {
                    img, h2 {
                        scale: 1.1;
                    }
                }
            }

            img {
                height: 120px;
                scale: 1;
                transition: scale 0.4s ease;
            }

            h2 {
                font-size: 34pt;
                margin: 0;
                scale: 1;
                transition: scale 0.4s ease;
            }
        }

        #sprout {
            img {
                width: 90%;
                max-width: 200px;
            }
        }

        #run-away {
            h2 {
                font-style: italic;
            }
        }

        #run-away, #compound, #everyday-portfolio, #year-end-review, #shoebox {
            img {
                height: 100px;
            }
        }

        #compound, #year-end-review, #shoebox {
            img {
                margin-bottom: 10px;
            }
        }
    }

    @media only screen and (max-width: 740px) {
        .project-grid-row {
            grid-template-columns: 100% !important;
            height: fit-content !important;
            row-gap: 25px;
            width: 100%;

            .project-tile {
                height: 350px;

                h2 {
                    font-size: 28pt;
                }

                img {
                    height: 100px;
                }
            }
    
            #run-away, #compound, #everyday-portfolio, #year-end-review, #shoebox {
                img {
                    height: 85px;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .project-grid-row {
            row-gap: 15px;
            margin-bottom: 15px;
    
        }
    }
}