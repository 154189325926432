.fold {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
    scroll-snap-align: start;
    scroll-snap-stop: always;
    margin-bottom: 100px;
    
    h1 {
        width: 100%;
        text-align: center;
        font-size: min(13vw, 128pt);
        margin: 0;
    }

    .above-the-fold-links {

        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
        max-width: 1400px;
        margin-bottom: 100px;

        a {
            font-family: 'Inconsolata', monospace;
            font-size: 14pt;
            text-decoration: none;
            padding: 5px 15px;
            padding-bottom: 7px;
            border-radius: 7px;
            background-color: transparent;
            color: var(--text-color-main);
            transition: background-color 0.4s ease;

            body.hasHover &:hover {
                background-color: var(--menu-bg-hover);
            }
        }
    }

    .fold-arrow {
        position: absolute;
        text-decoration: none;
        color: var(--text-color-main);
        bottom: 0px;
        padding: 40px;

        svg {
            font-size: 36pt;
            animation: slide-arrow 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;

            @keyframes slide-arrow {
                0% {
                    transform: translateY(0);
                }
                20% {
                    transform: translateY(30px);
                }
                40% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }

        &.safari {
            margin-bottom: 70px;
        }
    }
}

.landing-project-tiles {
    h2 {
        font-family: 'Inconsolata', monospace;
        color: var(--text-color-main);
        font-weight: 400;
        font-size: 32pt;
        white-space: pre-wrap;
        text-align: center;
        line-height: 40pt;
        margin: 0;
    }
}

.landing-intro-card {
    width: calc(100% - 50px);
    height: calc(100vh - 170px);
    margin-bottom: 150px;
    border-radius: 30px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    h2 {
        font-family: 'Inconsolata', monospace;
        color: var(--text-color-main);
        font-weight: 400;
        font-size: 32pt;
        white-space: pre-wrap;
        text-align: center;
        line-height: 40pt;
        margin: 0;
    }

    a {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 16pt;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        margin-top: 20px;
        width: fit-content;
        margin-left: 0;
        transition: margin-left 0.4s ease;

        svg {
            transform: rotate(-90deg);
            font-size: 32pt;
        }

        body.hasHover &:hover {
            margin-left: 10px;
        }
    }

    &#featured-project, &#work-experience {
        background-color: var(--intro-tile-outer);
        a {
            color: var(--link-color);
        }
    }

    &#featured-project {

        justify-content: space-between;
        
        .card-text {
            height: 43%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 5;
            a {
                color: var(--shoebox-tile);
                z-index: 5;
            }
        }
        img {
            position: absolute;
            display: flex;
            bottom: 0;
            align-self: center;
            max-height: 55%;
            max-width: 90%;
        }
    }

    &#technologies-overview {
        background-color: var(--intro-tile-inner);
        .card-text {
            height: 43%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .tech-imgs {
            max-height: 30%;
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;

            img {
                width: 25%;
                max-height: 80%;
                padding: 15px;
            }
        }
        a {
            color: var(--technologies-link);
        }
    }

    &#work-experience {
        .card-text {
            height: 43%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .work-imgs {
            max-height: 25%;
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;

            img {
                width: 25%;
                max-height: 80%;
                padding: 15px;
            }

            img:nth-child(2) {
                width: 35%;
            }

            img:last-child.top-end {
                margin-top: 15px;
            }

            #compass {
                margin-bottom: 15px;
            }
        }
    }
}

@media only screen and (max-width: 770px) {

    .landing-intro-card {
        margin-bottom: 100px;

        .card-text {
            h2 {
                font-size: min(6.3vw, 32pt);
                line-height: normal;
            }

            a {
                font-size: 14pt;
                svg {
                    font-size: 28pt;
                }
            }
        }
        

        &#featured-project {
        
        }

        &#work-experience {
            .work-imgs {
                max-height: 30%;
                width: 95%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;

                #compass {
                    width: 45%;
                    margin-top: 20%;
                }

                #vcu {
                    width: 45%;
                    margin-bottom: 20%;
                }

                img:last-child.top-end {
                    margin-top: 20%;
                }

                .top-end {
                    margin-top: 20%;
                    width: 35%;
                    height: 35%;
                }

                .bottom-end {
                    margin-bottom: 20%;
                    width: 35%;
                    height: 35%;
                }
            }
            
        }
    
        &#technologies-overview {
            .tech-imgs {
                max-height: 30%;
                width: 95%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;

                #css3 {
                    width: 28%;
                }

                .top-end {
                    margin-top: 20%;
                }

                .bottom-end {
                    margin-bottom: 20%;
                }
            }
        }
    }
}

.landing-project-tiles {
    margin-bottom: 50px;

    .landing-project-help-text {
        margin-bottom: 50px;
    }
    
    .tiles-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
        column-gap: 25px;
        row-gap: 25px;
        width: 100%;
    }

    .landing-page-project-card {
        border-radius: 30px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.4s ease; 

        img {
            height: 75px;
        }

        body.hasHover &:hover {
            transform: scale(1.04);
        }
    }
}

@media only screen and (max-width: 950px) {
    .landing-project-tiles {

        .landing-project-help-text {
            margin-bottom: 35px;
            font-size: 24pt;
            line-height:normal;
        }
    }
}

@media only screen and (max-width: 740px) {
    .landing-project-tiles {

        .landing-project-help-text {
            margin-bottom: 35px;
            font-size: min(9vw, 24pt);
            line-height:normal;
        }
    }
}