.gallery-landing {
    height: calc(100vh - 125px);
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.mobile {
        height: fit-content;

        .mobile-gallery-maps {
            
            .mobile-continent-block {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 100vw;

                &.clickable {
                    cursor: pointer;
                }

                img {
                    min-height: 80vh;
                    max-height: 100vh;
                }

                .title-text {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    h2 {
                        font-size: 44pt;
                        font-family: 'IBM PLex Mono';
                        line-height: 44pt;
                        margin: 0;
                        margin-bottom: 12px;
                        padding: 0 15px;
                        text-align: center;
                        font-style: italic;
                        font-weight: 500;
                        color: var(--travel-continent-mobile-text);
                    }

                    span {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: var(--copyright-text-color);

                        svg {
                            font-size: 16pt;
                            margin-right: 5px;
                        }
                    }
                }
            }

            @media only screen and (max-width: 500px) {
                .mobile-continent-block {
                    .title-text {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
    
                        h2 {
                            font-size: min(14vw, 40pt);
                            font-family: 'IBM PLex Mono';
                            line-height:100%;
                        }
                    }
                }
            }
        }
    }
    
    h1 {
        font-size: 28pt;
        font-weight: 700;
        margin: auto;
        text-align: center;
        z-index: 3;

        &.gallery-select-header {
            animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.4s;
            @keyframes fade-in-bottom {
                0% {
                    transform: translateY(10px);
                    will-change: filter;
                    filter: blur(10px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    will-change: filter;
                    filter: blur(0px);
                    opacity: 1;
                }
              }
        }

        &.gallery-select-continent {
            font-size: 36pt;
            animation: fade-in-continent 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            @keyframes fade-in-continent {
                0% {
                    transform: translateY(10px);
                    will-change: filter;
                    filter: blur(10px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    will-change: filter;
                    filter: blur(0px);
                    opacity: 1;
                }
            }
        }
    }

    .hover-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .select-help-text {
        margin: auto;
        text-align: center;
        z-index: 3;
        animation: fade-in-help-text 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        @keyframes fade-in-help-text {
            0% {
                transform: translateZ(500px);
                will-change: filter;
                filter: blur(10px);
                opacity: 0;
            }
            100% {
                transform: translateZ(0px);
                will-change: filter;
                filter: blur(0px);
                opacity: 1;
            }
        }

        &:not(.icon) {
            font-style: italic;
            color: var(--text-color-secondary);
        }

        &.icon {
            font-size: 24pt;
        }
    }

    .desktop-gallery-map-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            max-width: 120%;
            width: 120%;
            max-height: 120%;
        }

        .north-america, .south-america, .asia, .oceania, .europe, .antarctica, .africa {
            fill: var(--travel-continent-default-fill);
            cursor: pointer;
        } 
    }
}

.continent-gallery {
    
    .continent-header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: fit-content;
        margin-top: 30px;

        img {
            position: fixed;
            height: 300px;
            z-index: -1;
            left: 0px;
            top: 0px;
        }

        h2 {
            font-size: 24pt;
            font-weight: 500;
            margin: 15px 0;
        }

        .continent-svg {
            color: var(--travel-continent-default-fill);
        }

        svg {
            font-size: 16pt;
            padding: 10px;
            margin-left: -10px;
            margin-top: 2px;
            cursor: pointer;
            transform: translateX(0);
            transition: transform 0.3s ease;

            body.hasHover &:hover {
                transform: translateX(-5px);
            }
        }
    }

    .all-photos {
        height: calc(100vh - 223.5px);
        overflow: scroll;
    }

    @media only screen and (max-width: 500px) {
        .continent-header {
            margin-top: 20px;
        }

        .all-photos {
            height: calc(100vh - 188.5px);
        }
    }

    .country-section {

        h3 {
            font-family: 'Inconsolata', monospace;
            font-size: 24pt;
            font-weight: 500;
            margin: 0;
        }

        h4 {
            font-family: 'Inconsolata', monospace;
            font-size: 18pt;
            font-weight: 300;
            font-style: italic;
            margin: 0;
            margin-top: 8px;
            color: var(--copyright-text-color);

            

            &.upload-placeholder {
                display: flex;
                align-items: center;

                svg {
                    font-size: 16pt;
                    margin-right: 5px;
                }
            }
        }

        .image-grid {
            margin-top: 10px;
            padding-bottom: 10px;
            display: grid;
            column-gap: 15px;
            row-gap: 15px;
            width: 100%;
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    
            img {
                height: 100%;
                width: 100%;
                aspect-ratio: 1 / 1;
                border-radius: 15px;
                object-fit: cover;
                cursor: pointer;
                transform: scale(1);
                will-change: transform;
                transition: 0.3s ease;
    
                body.hasHover &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.continent-gallery .all-photos .country-section:not(:first-child) {
    margin-top: 25px;
}

.continent-gallery .all-photos .country-section:last-child {
    margin-bottom: 15px;
}

.group-test {
    fill: #ff0000;
}

.PhotoView__Photo {
    border-radius: 40px;
}

.PhotoView-Slider__Backdrop {
    will-change: filter;
    backdrop-filter: blur(5px);
}


.PhotoView-Slider__BannerWrap {
    display: none;
}