:root {
    --background-color: #ffffff;
    --background-color-transparent: #ffffff00;
    --header-background: #ffffff50;
    --text-color-main: #000000;
    --consistent-main: #000000;
    --text-color-secondary: #5e5e5e;
    --text-color-inverted: #ffffff;
    --copyright-text-color: #c1c1c1;
    --link-color: #1f31ff;
    --link-bg-color: #f3f3f3cc;
    --button-text-color: #ffffff;
    --toggle-icon-color: #9c9c9c;
    --menu-bg-hover: #f0f0f0;
    --menu-bg-active: #000000;
    --tile-bg-color: #f3f3f3;

    --the-blocks-tile: #ECECEC;
    --the-blocks-project-secondary: #d4d4d4;

    --shoebox-tile: #FFC832;
    --shoebox-tile-tile: #ffcf4c;

    --review-tile: #AA0000;
    --review-tile-tile: #c02020;
    --review-text: #fff5e1;

    --mitch-kitch-tile: #EAF2FF;
    --mitch-kitch-project-secondary: #9BCFFF;

    --everyday-portfolio-tile: #1DD000;
    --everyday-portfolio-tile-tile: #4cda36;

    --sprout-tile: #52CA8E;
    --sprout-tile-tile: #60dc9e;

    --compound-tile: #1B94FF;
    --compound-tile-tile: #5bb2ff;

    --run-away-tile: #F9F9F9;
    --run-away-tile-tile: #eeeeee;
    --run-away-text: #ff9b25;

    --tile-toggle-light: #ffffff9f;
    --tile-toggle-dark: #d8d8d8a4;
    --tile-toggle-ra: #d8d8d8a4;
    --intro-tile-outer: #F9F9F9;
    --intro-tile-inner: #E8EDFF;
    --technologies-link: #5F4DCF;
    --technologies-icon-highlight: #C2D0FF;
    --technologies-icon-shadow: #BDC9F3;

    --travel-hero-main: #000000;
    --travel-hero-button-bg: #000000;
    --travel-hero-button-text: #ffffff;
    --travel-continent-default-fill: #ececec;
    --travel-continent-highlight: #cae2cc;
    --travel-continent-mobile-text: #8aaa8c;
}

[data-theme="dark"] {
    --background-color: #131313;
    --background-color-transparent: #13131300;
    --header-background: #13131350;
    --text-color-main: #eaeaea;
    --consistent-main: #131313;
    --text-color-secondary: #999999;
    --text-color-inverted: #131313;
    --copyright-text-color: #525252;
    --link-color: #8a94ff;
    --link-bg-color: #131313;
    --button-text-color: #eaeaea;
    --toggle-icon-color: #252525;
    --menu-bg-hover: #363636;
    --menu-bg-active: #3C3C3C;
    --tile-bg-color: #525252;

    --the-blocks-tile: #2a2a2a;
    --the-blocks-project-secondary: #202020;

    --review-text: #fff5e1;

    --mitch-kitch-tile: #383e47;
    --mitch-kitch-project-icons: #43546c;
    --mitch-kitch-project-secondary: #30323b;

    --shoebox-tile: #FFC832;
    --shoebox-project-icons: #131313;
    --shoebox-tile-tile: #ffba1b;

    --everyday-portfolio-tile: #1DD000;
    --everyday-portfolio-tile-tile: #29c411;

    --review-tile: #AA0000;
    --review-tile-tile: #8b0808;
    --review-text: #fff5e1;

    --sprout-tile: #52CA8E;
    --sprout-tile-tile: #42b97e;

    --compound-tile: #1B94FF;
    --compound-tile-tile: #1288ef;

    --run-away-tile: #2c2c2c;
    --run-away-tile-tile: #353434;
    --run-away-text: #ff9b25;

    --tile-toggle-light: #1313139f;
    --tile-toggle-dark: #aeaeaea4;
    --tile-toggle-ra: #1313139f;
    --intro-tile-outer: #212121;
    --intro-tile-inner: #261D3A;
    --technologies-link: #72609C;
    --technologies-icon-highlight: #47386A;
    --technologies-icon-shadow: #362955;

    --travel-hero-main: #eaeaea;
    --travel-hero-button-bg: #000000;
    --travel-hero-button-text: #eaeaea;
    --travel-continent-default-fill: #2a2a2a;
    --travel-continent-highlight: #474a56;
    --travel-continent-mobile-text: #6c7493;
}

body {
    background-color: var(--background-color);
    color: var(--text-color-main);
}