.not-found {
    margin-top: 100px;

    h2 {
        font-size: 96pt;
        margin: 0;
        font-weight: 900;
        color: var(--tile-bg-color);
    }
    div {
        font-size: 32pt;
        font-family: 'Inconsolata', monospace;
        font-weight: 300;
        margin-bottom: 25px;
    }

    a {
        color: var(--link-color);
        font-family: 'Inter', sans-serif;
        font-size: 16pt;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: fit-content;
        transform: scale(1);
        transition: transform 0.6s ease;

        svg {
            margin-right: 5px;
            transform: translate(0);
            transition: transform 0.6s ease;
        }

        body.hasHover &:hover {
            transform: scale(1.05);

            svg {
                transform: translate(500px, -500px);
            }
        }
    }
}

#color-mode-toggle {
    svg {
        height: 100%;
        color: var(--toggle-icon-color);
        padding-left: 10px;
    }
}

.header {
    width: calc(100% - 50px);
    position: fixed;
    margin: 0;
    margin: auto;
    padding: 0 25px;
    top: 0;
    left: 0;
    background-color: var(--header-background);
    backdrop-filter: blur(15px);
    height: 75px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.4s ease;

    .header-content {
        width: 100%;
        max-width: 1400px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
    }

    a {
        text-decoration: none;
        color: var(--text-color-main);
        cursor: pointer;

        h1 {
            font-size: 20pt;
            white-space: nowrap;
        }
    }

    .desktop {
        display: flex;
        width: 350px;
        align-items: center;
        justify-content: space-between;

        a {
            text-decoration: none;
            color: var(--text-color-main);
            font-family: 'Inconsolata', monospace;
            background-color: transparent;
            padding: 5px 15px;
            padding-bottom: 7px;
            border-radius: 7px;
            transition: background-color 0.4s ease;

            &.active {
                color: var(--button-text-color);
                background-color: var(--menu-bg-active);
            }

            body.hasHover &:hover {
                background-color: var(--menu-bg-hover);

                &.active {
                    background-color: var(--menu-bg-active);
                }
            }
        }

        label {
            padding-left: 15px;
            display: flex;
            align-items: center;
        }

        &.hidden {
            display: none;
        }
    }

    section {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        transition: all 0.4s ease;

        .mobile-menu-toggle {
            padding: 15px;
            padding-bottom: 10px;
            padding-right: 0;

            svg {
                font-size: 24pt;
            }
        }

        &.close {
            .mobile-menu-toggle {
                padding-top: 3px;
                padding-right: 15px;
            }
        }
    }

    .mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        height: 0px;
        visibility: hidden;
        position: relative;
        right: -125px;
        transition: all 0.4s ease;  
        padding: 0 15px;

        a {
            text-decoration: none;
            color: transparent;
            font-family: 'Inconsolata', monospace;
            background-color: transparent;
            padding: 5px 15px;
            padding-bottom: 7px;
            border-radius: 7px;
            border: 1px solid var(--menu-bg-active);
            cursor: pointer;
        }

        label {
            opacity: 0;
            margin-top: 5px;
        }

        &.open {
            height: 175px;
            right: 0;
            visibility: visible;

            a {
                color: var(--text-color-main);
                background-color: transparent;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &.active {
                    color: var(--button-text-color);
                    background-color: var(--menu-bg-active);
                }
    
                body.hasHover &:hover {
                    background-color: var(--menu-bg-hover);
    
                    &.active {
                        background-color: var(--menu-bg-active);
                    }
                }
            }

            label {
                opacity: 1;
            }
        }
    }

    &.expanded {
        height: 275px;
        z-index: 50;

        .header-content {
            align-items: flex-start;
            z-index: 50;

            h1 {
                margin-top: 0px;
            }
        }
    }
}

footer {
    position: absolute;
    width: calc(100% - 50px);
    max-width: 1400px;
    margin: 0;
    margin: auto;
    padding: 0 25px;
    bottom: 0;
    left: 0;
    height: 50px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        font-family: 'Inconsolata', monospace;
        color: var(--copyright-text-color);

        span {
            display: flex;
            align-items: center;
        }

        svg {
            margin: 0 4px;
        }
    }

    .version-toggle {
        display: flex;
        align-items: center;
        font-family: 'Inconsolata', monospace;
        color: var(--link-color);
        cursor: pointer;

        svg {
            font-size: 14pt;
            margin-left: 10px;
            margin-right: 5px;
        }
    }
}

#mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 49;
}

sl-menu {
    border-radius: 15px;

    a {
        text-decoration: none;
    }
}

sl-menu-item::part(label) {
    display: flex;
    align-items: center;
    font-family: 'Inconsolata', monospace;
}

sl-menu-item {
    
    svg {
        margin-right: 5px;
        font-size: 16pt;
        color: orange;
    }

    .activated-version {
        color: #01d02d;
    }

    .inactive-version {
        color: var(--copyright-text-color);
    }
}

sl-menu-item .menu-item__check, .menu-item .menu-item__chevron {
    width: 1rem !important;
}

@media only screen and (max-width: 500px) {
    .header, footer {
        width: calc(100% - 30px);
        padding: 0 15px;
    }

    footer {
        flex-direction: column-reverse;

        .version-toggle {
            margin-bottom: 5px;
        }

        .copyright span {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}