// Inter
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// Inconsolata
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600&display=swap');
// IBM Plex Sans
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');
// Bitter
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,300;0,400;0,500;1,300&display=swap');
//IBM Plex Mono
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import './DarkMode.scss';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  margin: auto;
  position: relative;
  max-width: 1400px;
  padding: 50px 25px 75px 25px;
  font-family: 'Inter', sans-serif;
  color: var(--text-color-main);
  height: fit-content;
  min-height: calc(100vh - 125px);
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-size: unset;
}

@media only screen and (max-width: 740px) {
  body.sl-scroll-lock {
    --sl-scroll-lock-size: 25px !important;
  }
}

.sl-scroll-lock {
  padding-right: var(--sl-scroll-lock-size) !important;
  overflow: hidden !important;
}

@import './components/Header.scss';
@import './pages/Landing.scss';
@import './pages/Work.scss';
@import './pages/Project.scss';
@import './pages/Resume.scss';
@import './pages/travel/Travel.scss';
@import './pages//travel/Stats.scss';
@import './pages/travel/Gallery.scss';

@media only screen and (max-width: 500px) {
  body {
    padding: 50px 15px 50px 15px;
    min-height: calc(100vh - 100px);
  }
}