.project-showcase {
    margin-top: 50px;

    .back-to-work {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        font-size: 12pt;
        text-decoration: none;
        color: var(--button-text-color);
        cursor: pointer;
        scale: 1;
        transition: scale 0.3s ease;
        width: fit-content;
        background-color: var(--menu-bg-active);
        padding: 7px 15px;
        border-radius: 25px;

        svg {
            margin-right: 5px;
            font-size: 18pt;
        }

        body.hasHover &:hover {
            scale: 1.05;
        }
    }

    .project-not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 30vh;
        font-family: 'Inconsolata', monospace;
        animation: blur-in-not-found 0.4s ease;

            @keyframes blur-in-not-found {
                0% {
                    will-change: filter;
                    filter: blur(10px);
                    transform: translateY(20px);
                }
                100% {
                    will-change: filter;
                    filter: blur(0px);
                    transform: translateX(0px);
                }
            }

        a {
            color: var(--link-color);
            text-decoration: none;
            cursor: pointer;
        }

        svg {
            font-size: 24pt;
            margin-bottom: 12px;
        }
    }

    .project-loader {
        width: 100%;
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;

        sl-spinner.spinner {
            font-size: 48pt;
            --track-width: 10px;
            --track-color: var(--tile-bg-color);
            --indicator-color: var(--text-color-main);
        }
    }

    .project-card {
        padding: 25px 50px;
        border-radius: 25px;
        padding-bottom: 35px;
    
        &.animated {
            animation: fade-in-proj-card 0.4s ease;

            @keyframes fade-in-proj-card {
                0% {
                    opacity: 0;
                    will-change: filter;
                    filter: blur(10px);
                    transform: translateY(200px);
                }
                100% {
                    opacity: 1;
                    will-change: filter;
                    filter: blur(0px);
                    transform: translateX(0px);
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .project-card {
            padding: 25px;
        }
    }

    .project-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .project-brand {
            display: flex;
            align-items: center;
            
            img {
                margin-right: 10px;
            }
        }


        .project-chips {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            position: absolute;
            right: 0;
            width: 100%;
            padding: 10px 0 10px 10px;

            .tag {
                height: 50px;
                width: fit-content;
                padding: 0 10px;
                font-size: 22pt;
                border-radius: 15px;
                margin: 6px 6px 6px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: default;
                animation: fade-in-tags 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @keyframes fade-in-tags {
                    0% {
                        transform: translateX(15px);
                        will-change: filter;
                        filter: blur(5px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateX(0);
                        will-change: filter;
                        filter: blur(0px);
                        opacity: 1;
                    }
                  }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.large-icon {
                        font-size: 18pt;
                        margin: 0 2.665px;
                    }
                }

                &.count {
                    font-size: 16pt;
                    height: 50px;
                    width: 47px;
                    padding: 0;
                    padding-right: 3px;
                }

                .tag-name-wrapper {
                    display: grid;
                    grid-template-columns: 0fr;
                    overflow: hidden;
                    padding: 0;
                    opacity: 0;
                    transition: grid-template-columns 0.4s ease, padding 0.4s ease, opacity 0.4s ease;
                }

                body.hasHover &:hover {

                    .tag-name-wrapper {
                        grid-template-columns: 1fr;
                        padding-left: 5px;
                        opacity: 1;
                    }
                }

                .tag-name {
                    min-width: 0;
                    font-size: 14pt;
                    white-space: nowrap;
                }
            }

            .more {
                height: 29px;
                width: 29px;
                padding: 0;
                font-size: 16pt;
                cursor: pointer;
                border-radius: 25px;
                transform: rotate(0);
                margin: 0;
                transition: transform 0.5s ease, margin 0.5s ease;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                &.expanded {
                    margin-left: 10px;
                    transform: rotate(180deg);
                }
            }
        }

        img {
            height: 75px;
        }
    
        h1 {
            margin: 0;
            font-size: 32pt;
        }
    }

    #sprout {
        .project-header {
            .project-brand {

                img {
                    width: 90%;
                    max-width: 170px;
                    margin-right: 0;
                }
            }
        }
    }

    #run-away {
        .project-header {
            h1 {
                font-style: italic;
            }
        }
    }

    #run-away, #compound, #everyday-portfolio, #year-end-review {
        .project-header {
            img {
                height: 70px;
            }
        }
    }

    #shoebox {
        .project-header {
            img {
                height: 65px;
            }
        }
    }

    @media only screen and (max-width: 900px) {

        .project-header {

            .project-chips {
                will-change: backdrop-filter;
                backdrop-filter: blur(0px);
                transition: backdrop-filter 0.3s ease;

                &.expanded {
                    will-change: backdrop-filter;
                    backdrop-filter: blur(5px);
                }
            }
        }
    }

    @media only screen and (max-width: 740px) {
        .project-header {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                height: 60px;
            }

            h1 {
                margin: 0;
                font-size: 22pt;
            }

            .project-chips {
                will-change: backdrop-filter;
                backdrop-filter: blur(0px);
                transition: backdrop-filter 0.3s ease;
                padding: 0;
                justify-content: center;
                margin-top: 75px;
                top: 0;

                &.expanded {
                    will-change: backdrop-filter;
                    backdrop-filter: blur(0px);
                }

                .last-expanded {
                    margin-right: 0 !important;
                }

                .more {
                    display: none;
                }
            }
        }

        #sprout {
            .project-header {
                .project-brand {
                    justify-content: center;
                }
            }
        }

        #run-away, #compound, #everyday-portfolio, #year-end-review {
            .project-header {
                img {
                    height: 55px;
                }
            }
        }

        #shoebox {
            .project-header {
                img {
                    height: 50px;
                }
            }
        } 
    }

    @media only screen and (max-width: 625px) {
        .project-header {

            .project-chips {
                .tag {
                    body.hasHover &:hover {

                        .tag-name-wrapper {
                            grid-template-columns: 0fr;
                            padding-left: 0px;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    label {
        font-size: 16pt;
        font-weight: 500;

        &.highlight-label {
            text-align: center;
            margin-bottom: 25px;    
        }
    }

    .project-hero-items {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tagline {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 48pt;
            height: 75vh;

            &.left-aligned {
                text-align: left;
                margin: auto;
            }
        }

        .highlight-tiles {
            display: flex;
            width: 100%;
            max-width: 850px;
            justify-content: space-evenly;

            .highlight-tile {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                font-size: 10pt;
                height: 115px;
                padding: 10px;
                width: 115px;
                border-radius: 25px;

                svg {
                    font-size: 24pt;
                }
            }
        }

        @media only screen and (max-width: 740px) {
            .highlight-tiles {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 25px;
                row-gap: 25px;
                max-width: 290px;
    
                .highlight-tile {
                  justify-self: center;
                }
            }
        }

        @media only screen and (max-width: 375px) {
            .highlight-tiles {
                display: grid;
                grid-template-columns: 1fr;
                row-gap: 15px;
                max-width: unset;
    
                .highlight-tile {
                  width: 100%;
                  font-size: 12pt;
                }
            }
        }
    }

    #compound, #sprout, #year-end-review {
        .project-hero-items {
    
            .tagline {
                max-width: 750px;
                margin: auto;
                font-size: 44pt;
            }
        }
    }

    @media only screen and (max-width: 740px) {
        .project-hero-items {
        
            .tagline {
                font-size: 8vw;
            }
        }

        #the-mitch-kitch {
            .project-hero-items {
        
                .tagline {
                    font-size: 12vw;
                }
            }
        }

        #compound, #year-end-review, #sprout {
            .project-hero-items {
        
                .tagline {
                    font-size: 7vw;
                }
            }
        }

        #sprout {
            .project-hero-items {
        
                .tagline {
                    font-size: 6vw;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .project-header {
            align-items: center;

            h1 {
                font-size: 24pt;
                text-align: center;
            }
        }
    }

    .project-content {
        margin-top: 75px;
        margin-bottom: 25px;

        .proj-section-header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            margin-top: 50px;
    
            div {
                display: flex;
                align-items: center;
            }
    
            svg {
                margin-right: 5px;
                font-size: 26pt;
            }
        }

        .description {
            font-size: 22pt;
            max-width: 1260px;

            p {
                margin-bottom: 15px;
                margin-top: 0;
                font-size: 18pt;
            }

            h4 {
                margin-bottom: 10px;
                font-weight: 500;
                font-style: italic;
                font-size: 16pt;
            }
        }

        @media only screen and (max-width: 740px) {
            .description {
                font-size: 14pt;

                p {
                    margin-bottom: 15px;
                    margin-top: 0;
                    font-size: 14pt;
                }
            }
        }

        .links {
            .link-tiles {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 6px;
                    margin-bottom: 12px;
                    padding: 10px 25px;
                    border-radius: 15px;
                    text-decoration: none;
                    font-size: 14pt;
                    cursor: pointer;
                    opacity: 1;
                    transform: scale(1);
                    transition: opacity 0.3s ease, transform 0.3s ease;

                    svg {
                        font-size: 18pt;
                        margin-left: 5px;
                    }

                    body.hasHover &:hover {
                        opacity: 0.8;
                        transform: scale(1.04);
                    }
                }
            }
        }

        @media only screen and (max-width: 400px) {
            .links {
                .link-tiles {
    
                    a {
                        margin: 0;
                        margin-bottom: 15px;
                        padding: 10px 20px;
                        width: 100%;
                        font-size: 12pt;
    
                        svg {
                            font-size: 14pt;
                        }
                    }
                }
            }
        }

        .visuals {
            sl-carousel::part(base) {
                gap: unset;
                height: 500px;
            }

            sl-carousel-item {
                height: 500px;
                
            }

            sl-carousel::part(scroll-container) {
                padding: 2px;
                --slide-gap: 12px;
            }

            img {
                border: 2px solid var(--background-color);
                border-radius: 25px;
                height: 100%;
                min-height: 100%;
                width: auto;
                max-width: 400px;
                object-fit: cover;
            }

            .not-mobile-screenshot {
                border: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                min-height: unset;
                height: unset;
                width: 120%;
                max-width: 800px;
                padding-left: 55px;

                &.phone {
                    max-width: 500px;
                    padding: 0;
                    margin-bottom: 50px;
                }
            }

            @media only screen and (max-width: 950px) {
                .not-mobile-screenshot {
                    padding-left: 0px;
    
                    &.phone {
                        width: 100%;
                    }
                }
            }

            @media only screen and (max-width: 800px) {
                .not-mobile-screenshot {
                    margin-left: -15px;
    
                    &.phone {
                        margin: auto;
                        margin-bottom: 50px;
                    }
                }
            }

            sl-carousel::part(navigation-button--previous) {
                font-size: 24pt;
                padding: 0;
                padding-right: 10px;
            }

            sl-carousel::part(navigation-button--next) {
                font-size: 24pt;
                padding: 0;
                padding-left: 8px;
            }
        }

        @media only screen and (max-width: 400px) {
            .visuals {

                sl-carousel {
                    margin-left: -10px;
                    margin-right: -10px;
                }

                sl-carousel::part(base) {
                    gap: unset;
                    height: 425px;
                }
    
                sl-carousel-item {
                    height: 425px;
                    
                }
    
                img {
                    border: 2px solid var(--background-color);
                    border-radius: 25px;
                    height: 100%;
                    min-height: 100%;
                    width: auto;
                    max-width: 400px;
                    object-fit: cover;
                }
    
                sl-carousel::part(navigation-button--previous) {
                    font-size: 18pt;
                    padding: 0;
                    padding-right: 3px;
                }
    
                sl-carousel::part(navigation-button--next) {
                    font-size: 18pt;
                    padding: 0;
                    padding-left: 0px;
                }
            }
        }
    }
}