.resume {
    margin-top: 30px;

    .section-heading {
        font-family: 'Inconsolata', monospace;
        font-size: 16pt;
    }

    .intro {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        img {
            height: 250px;
            border-radius: 50%;
            margin-left: 15px;
            margin-top: 10px;
        }

        h2 {
            font-size: 48pt;
            font-weight: 500;
            margin: 0;
        }

        p {
            font-family: 'Inconsolata', monospace;
            font-size: 18pt;
            margin: 10px 0;
        }

        .intro-text {
            .social-icons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 150px;
                margin-top: 25px;

                .link {
                    text-decoration: none;
                    

                    svg {
                        font-size: 24pt;
                        cursor: pointer;
                        color: var(--text-color-main);
                    }
                }
            }
        }

        .mobile-intro-text {
            display: none;
        }
    }

    @media only screen and (max-width: 740px) {

        .intro {

            img {
                height: 100px;
                border-radius: 50%;
            }

            .intro-text {
                margin-bottom: 5px;

                .social-icons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 175px;
                    margin-top: 10px;

                    .link {
                        svg {
                            font-size: 18pt;
                        }
                    }
                }
            }
    
            h2 {
                font-size: 28pt;
                font-weight: 600;
                margin: 0;
            }

            p {
                font-size: 14pt;
                margin: 0;
                display: none;
            }

            .mobile-intro-text {
                display: inline;
            }

            .mobile-intro-text::after {
                content: "designer.";
                animation: intro-animation 10s both infinite;
            }

            @keyframes intro-animation {
                0% {opacity: 1; content: "designer.";}
                20% {opacity: 1; will-change: filter; filter: blur(0px); content: "designer.";}
                23% {opacity: 0; will-change: filter; filter: blur(12px); content: "designer.";}
                26% {opacity: 0; will-change: filter; filter: blur(12px); content: "developer.";}
                29% {opacity: 1; will-change: filter; filter: blur(0px); content: "developer.";}
                50% {opacity: 1; will-change: filter; filter: blur(0px); content: "developer.";}
                53% {opacity: 0; will-change: filter; filter: blur(12px); content: "developer.";}
                56% {opacity: 0; will-change: filter; filter: blur(12px); content: "traveler.";}
                59% {opacity: 1; will-change: filter; filter: blur(0px); content: "traveler.";}
                86% {opacity: 1; will-change: filter; filter: blur(0px); content: "traveler.";}
                89% {opacity: 0; will-change: filter; filter: blur(12px); content: "traveler.";}
                93% {opacity: 0; will-change: filter; filter: blur(12px); content: "designer.";}
                96% {opacity: 1; will-change: filter; filter: blur(0px); content: "designer.";}
            }
        }
    }

    .disclaimer {
        margin: 35px 0;
    }

    .disclaimer-container {
        background-color: var(--tile-bg-color);
        max-width: fit-content;
        padding: 25px;
        font-family: 'Inconsolata', monospace;
        font-size: 16pt;
        border-radius: 25px;
        margin: auto;

        a {
            color: rgb(47, 68, 255);
            transition: color 0.4s ease;
            cursor: pointer;

            body.hasHover &:hover {
                color: rgb(75, 171, 255);
            }
        }
    }

    @media only screen and (max-width: 740px) {
        .disclaimer-container {
            font-size: 14pt;
            padding: 20px 25px;
        }
    }

    .education, .experience {
        margin-bottom: 50px;
        max-width: 625px;

        .edu-header, .exp-header {
            display: flex;
            align-items: center;
            
            svg {
                font-size: 16pt;
                margin-left: 15px;
                margin-top: 2px;
                cursor: pointer;
            }
        }

        .item {
            margin-top: 15px;
            line-height: 24pt;

            span {
                font-size: 12pt;
            }

            h3 {
                font-size: 16pt;
                margin: 0;
            }

            p {
                font-size: 16pt;
                margin: 0;
            }

           

            .multiple-positions {
                display: flex;
                flex-direction: column;
                padding-left: 15px;

                .sub-dates {
                    font-size: 12pt;
                    line-height: 12pt;
                }

                svg {
                    font-size: 16pt;
                    margin-top: 10px;
                    margin-left: -2px;
                    color: var(--copyright-text-color);
                }
            }

            .multiple-positions:first-of-type {
                svg {
                    margin-top: 5px;
                }
            }

            .multiple-positions:last-of-type {
                padding-bottom: 10px;
            }

            .experience-detail-wrapper {
                display: grid;
                grid-template-rows: 0fr;
                overflow: hidden;
                padding: 0;
                opacity: 0;
                transition: grid-template-rows 0.3s ease, padding 0.3s ease, opacity 0.3s ease;

                &.expanded {
                    grid-template-rows: 1fr;
                    padding-top: 10px;
                    padding-bottom: 15px;
                    opacity: 1;

                    &.docs {
                        padding: 0;
                    }

                    &.multiple {
                        padding-bottom: 5px;
                    }
                }

                .edu-docs-container {
                    min-height: 0;

                    .edu-docs {
                        font-family: 'Inconsolata', monospace;
                        font-size: 12pt;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        color: var(--link-color);
                        text-decoration: none;
                        height: 28px;
                        width: fit-content;
        
                        svg {
                            font-size: 18pt;
                            margin-right: 7px;
                        }

                        svg.narrow-cert {
                            margin-right: 4px;
                            margin-left: -3px;
                        }
                    }
                }

                ul {
                    min-height: 0;
                    margin: 0;
                    padding: 0;
                    line-height: 14pt;
                    stroke-linecap: round;
                    color: var(--text-color-secondary);

                    li:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .proficiencies {
        margin: 25px 0;

        h3 {
            font-size: 16pt;
            display: flex;
            margin: 0;
            margin-top: 15px;
            align-items: center;
            width: fit-content;
            cursor: pointer;

            svg {
                margin-left: 5px;
                transform: rotate(180deg);
                transition: transform 0.3s ease;

                &.down {
                    transform: rotate(0);
                }
            }
        }

        .section-list-wrapper {
            display: grid;
            grid-template-rows: 0fr;
            overflow: hidden;
            margin: 0 0;
            opacity: 0;
            transition: all 0.3s ease;

            &.open {
                margin: 20px 10px; 
                margin-top: 7px;
                opacity: 1;
                grid-template-rows: 1fr;
            }
        }

        .section-list {
            display: flex;
            flex-direction: column;
            min-height: 0;

            span {
                margin-bottom: 7px;
            }

            span > small {
                display: block;
                margin-top: -5px;
                margin-bottom: 0px;
                font-style: italic;
                color: var(--copyright-text-color);
                font-family: "Inconsolata", monospace;
            }
        }
    }

    .digital-copy-text {
        display: flex;
        align-items: center;
        font-family: 'Inconsolata', monospace;
        font-size: 14pt;
        text-align: center;
        white-space: nowrap;

        p {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin: 0;

            a {
                color: var(--link-color);
                cursor: pointer;
                text-decoration: none;
            }
        }

        svg {
            font-size: 24pt;
            margin-right: 15px;
        }

       
    }
}

@media only screen and (max-width: 500px) {
    .resume {
        margin-bottom: 25px;
    }
}